import React from 'react';
import styled from 'styled-components';

const ListItem = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const Item = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  margin: 10px;
  border: 5px solid #446971;
  border-radius: 12px;
  background: #f8f9fa;
  box-shadow: 10px 15px 20px rgba(66, 68, 90, 0.2);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.6em;
    margin-bottom: 15px;
    color: #2e3d49;

    span.promo {
      color: #28a745;
      font-size: 1.8rem;
    }
  }

  h5 {
    font-size: 1.3em;
    text-align: left;
    text-decoration: underline;
    margin-bottom: 10px;
    color: #6c757d;
  }

  ul {
    list-style: none;
    padding: 0;
    color: #495057;
  }
  
  ul li::before {
    content: "✔";
    color: #28a745;
    font-weight: bold;
    padding-right: 10px;
  }
`;

const Note = styled.p`
  text-align: center;
  font-size: 1rem;
  color: #6c757d;
  margin-top: 20px;
`;

const Pakiet = () => {
  return (
    <>
      <ListItem>
        <Item>
          <h3>Pakiet Piccolo od <span className='promo'>319PLN</span></h3>
          <h5>Na starcie</h5>
          <ul>
            <li>Jedna ze specjalnie zaprojektowanych tras i w 100% przejechana przez Nas - km po km</li>
            <li>System śledzenia online (wypożyczenie urządzenia GPS)</li>
            <li>Pit-stop – napoje, przekąski</li>
            <li>Numer startowy</li>
            <li>Pamiątkowa naszywka Ultra Race Dolina Bugu</li>
            <li>Torba Ultra Race Dolina Bugu</li>
            <li>Pyszną niespodziankę na starcie</li>
            <li>Możliwość pozostawienia depozytu</li>
            <li>Obsługę zawodów, możliwość korzystania z infrastruktury w bazie zawodów</li>
          </ul>
          <h5>Na mecie</h5>
          <ul>
            <li>Pamiątkowe trofeum</li>
            <li>Ciepły posiłek regeneracyjny</li>
          </ul>
        </Item>
        <Item>
          <h3>Pakiet Classico/Gigante od <span className='promo'>419PLN</span></h3>
          <h5>Na starcie</h5>
          <ul>
            <li>Jedna ze specjalnie zaprojektowanych tras i w 100% przejechana przez Nas - km po km</li>
            <li>System śledzenia online (wypożyczenie urządzenia GPS)</li>
            <li>Pit-stop – napoje, przekąski, ciepły posiłek</li>
            <li>Numer startowy</li>
            <li>Pamiątkowa naszywka Ultra Race Dolina Bugu</li>
            <li>Torba Ultra Race Dolina Bugu</li>
            <li>Pyszną niespodziankę na starcie</li>
            <li>Możliwość pozostawienia depozytu</li>
            <li>Obsługę zawodów, możliwość korzystania z infrastruktury w bazie zawodów</li>
            <li>Pakiet zdjęć wykonany przez profesjonalny zespół</li>
          </ul>
          <h5>Na mecie</h5>
          <ul>
            <li>Pamiątkowe trofeum</li>
            <li>Ciepły posiłek regeneracyjny</li>
          </ul>
        </Item>
      </ListItem>
      <Note>*Pakiet można poszerzyć o: skarpetki URDB (38 zł/szt.) lub t-shirt URDB (108 zł).</Note>
      <Note>*Opłata startowa ulega systematycznemu wzrostowi. Do 31.03.2025 - 319 zł (Piccolo), 419 zł (Classico/Gigante). Do 01.04.2025 - 399 zł, 499 zł.</Note>
    </>
  );
};

export default Pakiet;
