import React from 'react';
import Layout from '../components/Layout';
import { SEO } from "../components/Seo";
import Countdown from "../components/countdown";
import styled from 'styled-components';
import Pakiet from '../components/Pakiet';
import regulaminDolina from "../assets/URDB25_regulamin.pdf";
import HeroZapisy from '../components/HeroZapisy';
import GaleriaSection from '../components/GaleriaSection';
import DiscountInfo from '../components/DiscountInfo';
import MiniGallery from '../components/MiniGallery';

const Title = styled.div`
    text-align: center;
    padding: 30px 10px;
    max-width: 900px;
    margin: 0 auto;

    h1 {
        padding: 20px 0;
        font-size: 2.5em;
        font-weight: bold;
        color: #2c5b3b;
        ${({theme}) => theme.media.tablet} {
            font-size: 3em;
        }
    }

    h2 {
        font-size: 1.6em;
        font-weight: 500;
        padding: 20px;
        margin: 2%;
        color: #1d3b25;
    }
`;

const ButtonZapisy = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 30px auto;
    color: white;
    background-color: #2c5b3b;
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    padding: 15px 30px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    max-width: 300px;
    text-decoration: none;
    border: 2px solid transparent;
    position: relative;
    z-index: 10; /* Poprawione: aby nie było konfliktu */

    &:hover {
        background-color: #1d3b25;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    }
`;

const StyledLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const StyledLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: 500;
    color: #2c5b3b;
    text-decoration: none;
    padding: 12px 25px;
    border: 2px solid #2c5b3b;
    border-radius: 5px;
    max-width: 250px;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: #2c5b3b;
        color: white;
    }
`;

const HeroZapisyStyled = styled.div`
    .img {
        height: 90vh;
        object-fit: cover;
        ${({theme}) => theme.media.tablet} {
            height: 90vh;
        }
    }
`;

const Zapisy = () => {
    return (
        <Layout>
            <HeroZapisyStyled>
                <HeroZapisy/>
            </HeroZapisyStyled>
            <Title>
                <h1>Odkryj Dolinę Bugu i podejmij wyzwanie!</h1>
                <h2>Zapisy startują <strong>22.02.2025 o godzinie 20:00</strong></h2>
            </Title>
            <Pakiet/>
            <ButtonZapisy href="https://b4sportonline.pl/ultra_race/" target="_blank">Weź udział!</ButtonZapisy>

            <StyledLinkWrapper>
                <StyledLink href={regulaminDolina} title="Regulamin Ultra Race Dolina Bugu">📜 Pobierz Regulamin</StyledLink>
            </StyledLinkWrapper>

            <Countdown/>
            <DiscountInfo />
        </Layout>
    )
}

export default Zapisy;

export const Head = () => (
  <SEO title="Zapisy | Ultramaraton Rowerowy" description="Zapisz się na Ultramaraton Rowerowy Dolina Bugu."/>
);
