import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const GallerySection = styled.section`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100vw;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative; /* ✅ NIE nachodzi na przycisk */
    max-height: 500px; /* ✅ Ogranicza wysokość */

    ${({ theme }) => theme.media.mobile} {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        max-height: 300px; /* ✅ Mniejsza wysokość na mobile */
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    ${({ theme }) => theme.media.mobile} {
        &:nth-child(n+2) { 
            display: none; /* ✅ Tylko pierwsze zdjęcie na mobile */
        }
    }
`;

const MiniGallery = () => {

    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "galzapisy"}}) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
        }
    `);

    const images = data.allFile.nodes;

    return (
        <GallerySection>
            {images.slice(0, 9).map((image, index) => (
                <ImageWrapper key={index}>
                    <GatsbyImage image={getImage(image.childImageSharp)} alt={`Galeria zdjęcie ${index + 1}`} />
                </ImageWrapper>
            ))}
        </GallerySection>
    );
};

export default MiniGallery;
