import React from 'react';
import styled from 'styled-components';

const DiscountSection = styled.section`
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(135deg, #d4e7d1 0%, #9bbf9d 100%);
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;

    @keyframes fadeIn {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
    }

    h2 {
        font-size: 2.2rem;
        font-weight: bold;
        color: #2f5233;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    p {
        font-size: 1.3rem;
        color: #1d3b25;
        max-width: 800px;
        margin: 0 auto;
        line-height: 1.6;
    }

    strong {
        color: #3b6f41;
        font-weight: bold;
    }
`;

const DiscountInfo = () => {
    return (
        <DiscountSection>
            <h2>Stały Rabat dla Weteranów</h2>
            <p>
                Jeśli brałeś udział w którymkolwiek z naszych wcześniejszych wyścigów, masz prawo do <strong>rabatu 50 zł</strong> na Ultra Race Dolina Bugu! To nasz sposób na podziękowanie za Twoją lojalność i pasję. Kod rabatowy zostanie wysłany do Ciebie e-mailem po rozpoczęciu zapisów.
            </p>
        </DiscountSection>
    );
};

export default DiscountInfo;
